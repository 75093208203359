import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

// import imgProfileKevinMortlock from "../images/profile-kevin-mortlock.jpg"
import imgCaseStudy from "../images/plumpton-college-campus.jpg"
import imgStudentQRCode from "../images/student-coach-qr-code.jpg"
import "./index.css";
import "./case-study.css";
import Nav from "../components/nav"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../components/contact"
import Footer from "../components/footer"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Case study: How Plumpton College's travel transformation saved a full-time salary with ShuttleID" />
        <Nav activeTab="case-studies" />
        <div>
            <div>

              <div className="case-study">

                <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                  <h1 className="blog-headline">Plumpton College cuts stress and saves a full-time salary using ShuttleID</h1>
                </div>

                <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                  <h1 className="blog-headline">Plumpton College cuts stress and saves a full-time salary using ShuttleID</h1>
                </div>

                <div className="article">
                  <div className='article-intro article-inner'>
                    <p>Where to next? That was the question Plumpton College asked itself after exhausting many avenues to help its students travel to college. Despite plenty of bright ideas – from dispensing multi-use tickets to launching online prepayments and photo IDs – nothing was proving quick or easy, and the people at Plumpton were left scratching their heads.</p>
                    <p>Whilst considering if other schools were using similar time-consuming processes, Rebecca King, Student Welfare Officer at Plumpton College, began researching ticketing systems and found ShuttleID.</p>
                    <p>After a quick and easy setup, Plumpton College began saving valuable admin time, equivalent to one full-time member of staff. The College also improved safeguarding by documenting fully students' journeys. After several years of failed systems, Plumpton’s problems were finally resolved.</p>
                  </div>
                  <div className='article-inner'>
                    <div className='case-study__metrics'>
                      <div className='case-study__metric'>
                        <span className='case-study__metric-val'>1,560 hrs</span>
                        admin saved annually
                      </div>
                      <div className='case-study__metric'>
                        <span className='case-study__metric-val'>228,000</span>
                        journeys protected annually
                      </div>
                    </div>
                    <div className='case-study__about'>
                      <h3>About Plumpton College </h3>
                      <p><a href="https://www.plumpton.ac.uk/">Plumpton College</a> is a Further and Higher education college in East Sussex, England. The college is one of only 13 independent land and environmental science colleges nationally, offering a broad, specialist curriculum in 19 course areas.</p>
                    </div>

                    <h2>A notable-size network</h2>
                    <p>Plumpton College manages its own transport to ensure pupils can travel safely to and from the campus via 12 routes. With 800 full-time students and several thousand attending part-time courses across 2,500 acres, the college’s transport network is vital to its success.</p>
                    <p>Over the years, Plumpton has invested in several concepts for processing travel tickets, passes and payments, but none quite answered the brief. Despite their best efforts, Rebecca and her colleagues endured plenty of late nights catching up on admin, reprinting passes and working out the best way to cut down on potential fraud.</p>
                    <p><img src={imgCaseStudy} alt="Plumpton College campus" /></p>

                    {/* <div className='case-study__quote'>
                      <p>"It was quite a nightmare, really."</p>
                      <p className='case-study__quote-author'>&mdash; Kevin Mortlock, Managing Director, Keane Travel Ltd</p>
                    </div> */}


                    <h2>Capacity overload</h2>
                    <p>At first, Plumpton College started off with a basic paper card system. Each card was printed with 10 boxes and one box was crossed off each time a student boarded a bus. With hindsight, the team realised they couldn’t predict how many students would travel a route on any given day. Some days the buses would overfill, leaving the team having to organise an extra minibus.</p>


                    <h2>Payment loophole</h2>
                    <p>The following year, Plumpton decided to expand its online shop in the hope that technology could provide more control. If students could buy a travel ticket through the College website and receive a payment receipt, they could use the receipt to board the bus. Sadly, a sizable minority found a way to exploit the system and receipts were duplicated and distributed amongst friends. The system couldn’t keep track of passenger numbers and the College lost important income.</p>

                    <h2>Clamping down</h2>
                    <p>In a bid to reduce fraud, Plumpton introduced ID cards. With a student’s name and travel route clearly labelled, it was evident that ID cards couldn’t be shared. Whilst the idea worked in principle, it proved time-consuming and labour-intensive to implement. The team repeatedly worked late to get cards ready, and in the meantime, some students gleaned free journeys.</p>
                    <p>“We spent so many late nights trying to print out all these tickets,” said Rebecca.</p>

                    <h2>Too many processes, too little manpower</h2>
                    <p>The main headache Rebecca faced was lack of time. Even with the offer of an extra staff member to help with the processing, the team was up against it. What’s more, the online shop had no way of managing bus and route capacity; the system couldn’t cope with demand.</p>
                    <p>Rebecca said, “The ID cards worked better than the paper cards, but the system still wasn’t perfect; we were just chasing our tails. It wasn’t sustainable and it still didn’t resolve key problems.”</p>

                    <h2>An industry-specific ticketing system</h2>
                    <p>In September 2020, after another long stint of printing ID cards, Rebecca resolved to find a simpler way of handling passes. She was keen to find out whether other schools endured similar issues and if a more advanced ticketing system existed. Whilst Rebecca found companies able to process simple bus tickets, she found them geared to public transport. Rebecca needed more tailored technology – to limit capacity and avoid bus overcrowding in the first instance.</p>

                    <h2>Instant relief</h2>
                    <p>When Rebecca saw a demo of ShuttleID, she understood immediately it could solve a huge problem. Its ability to set the number of seats per route and prevent capacity from being oversold was music to her ears.</p>
                    <p>Rebecca was also drawn to ShutteID’s QR codes, which display tickets on a passenger’s mobile device. Other systems she’d looked at still used ID cards – like scan and swipe RFID cards. But using yet another card system would leave Rebecca having to pair passes with students once again. She’d also seen that ID cards didn’t work well for apprentices or students on short courses. She said, “For students attending courses for just one week, we’d spend a disproportionate amount of time getting their card set up. With ShuttleID’s QR codes, it's instant.”</p>
                    <p><img src={imgStudentQRCode} alt="Student using QR code" /></p>

                    <h2>Auto-generated tickets</h2>
                    <p>After signing up with ShuttleID, Rebecca began the setup process. She found the online platform much easier for loading tickets than her previous online shop. Plus, with passengers uploading their own details and photo, Rebecca saved masses of admin time. She watched the online ticket purchases arrive and instant tickets leave via the system’s back office; she didn’t need to lift a finger.</p>
                    <p>“Usually, I'd have to schedule two days in the diary to load all our tickets onto the online shop, but with ShuttleID it took a couple of hours to get sorted for the whole year.” </p>

                    <h2>Better for bill payers</h2>
                    <p>Today, once purchased securely online, the ShuttleID travel tickets display as a QR code on the passenger’s mobile. Tickets can also be printed out as backup. If a student’s phone battery dies or their screen is smashed, Plumpton College can help by printing out a replacement ticket on the day.</p>
                    <div className='case-study__quote'>
                      <p>"Before, students were always losing their bus tickets, but they never seem to lose their phones! It has been much, <strong>much</strong> simpler."</p>
                      <p className='case-study__quote-author'>&mdash; Rebecca King, Student Welfare Office, Plumpton College</p>
                    </div>
                    {/* <p>“Before, students were always losing their bus tickets, but they never seem to lose their phones! It has been much, much simpler,” Rebecca said.</p> */}
                    <p>Rebecca has also found the level of support from ShuttleID incredibly responsive. Rebecca said, “I remember emailing one weekend, and I got a response and a change on the website there and then.”</p>

                    <h2>Putting Plumpton in charge</h2>
                    <p>Since going live, Rebecca has used the journey logs to uncover travel patterns, which help her decide if further transport is needed on busy days, making sure nobody is left out in the cold.</p>
                    <p>In fact, she’s realised ShuttleID does a lot more than confirming she has the right passenger numbers; the system is paying dividends regarding safeguarding and behaviour issues. Recently, for example, when a student reported being bullied on the bus, but they didn't know who it was, Plumpton simply sifted through the passenger list and photos to identify the troublemaker. </p>

                    <h2>Clearer communication</h2>
                    <p>The SMS service text alerts are an added feature that helping with safeguarding. Rebecca recalls an emergency where she needed to get an important message out and required the details of those onboard a bus. Now she has that information at her fingertips and can send messages instantly. She said, “To have these features makes me feel much more in control.”</p>
                    <p>Overall, Plumpton College estimates that using ShuttleID has saved the cost of one extra full-time member of staff. Importantly, Rebecca now has much more time to focus on her role as Student Welfare Officer, rather than as the sorter of bus tickets!</p>


                    {/* <div className='case-study__profile-quote'>
                      <div className='case-study__profile-quote__img'>
                        <img src={imgProfileKevinMortlock} alt="Ceri Taylor" />
                      </div>
                      <div className='case-study__profile-quote__quote'>
                        <p>"Recently, I was on holiday and, as usual, I took my laptop with me. Every other day I went on my ShuttleID portal to see how many tickets I’d sold and how close I was to my new targets. Compare that with two years ago? What a difference! With the customer numbers we’re doing now, the old system would have been Bedlam. This holiday I didn’t have to contact any parents or help set up standing orders. All I had to do was watch the numbers creep up. I’m now exceeding my targets, so it has definitely been a game changer."</p>
                        <p className='case-study__profile-quote__author'>Kevin Mortlock, Managing Director, Keane Travel Ltd</p>
                      </div>
                    </div> */}

                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage
